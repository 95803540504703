<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12" v-if="!stockId">
        <v-model-table model-name="Stocks" model-title="Stocks"
          :model-api="['models','Stock','Stocks']" :headers="headers" importable searchable>
          <template v-slot:modelForm="{data,rules,isCreating}">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field :rules="[rules.require()]" v-model="data.stockName" label="ชื่อคลังสินค้า"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.action="props">
            <v-btn :to="{name:'inventory.stock', params: {stockId: props.item.id}}" color="primary"><v-icon class="mx-1">mdi-circle-edit-outline</v-icon> เปิด</v-btn>
          </template>
        </v-model-table>
      </v-col>
      <v-col cols="12" v-if="stockId">
        <v-card color="white">
          <v-card-text class="pa-0">
            <v-container py-2 fluid>
              <v-row dense>
                <v-col cols="11" align-self="center" class="text-left">
                  <v-label-api :api-url="['models','Stock','Stocks','find']" :api-key="stockId" api-field="stockName" class="display-1"></v-label-api>
                </v-col>
                <v-col cols="1" align-self="center" class="text-right">
                  <v-btn icon :to="{name:'inventory.stock'}"><v-icon>close</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card> 
      </v-col>
      <v-col cols="12" v-if="stockId">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="stocksproducts">Stocks Products</v-tab>
            <v-tab key="stockscards">Stocks Cards</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="stocksproducts">
              <v-stocks-products :stockId="stockId"></v-stocks-products>
            </v-tab-item>
            <v-tab-item key="stockscards">
              <v-stocks-cards :stockId="stockId"></v-stocks-cards>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: ()=>({
    headers: [
      {
        text: 'Stock ID',
        value: 'id',
        class: 'body-2',
        width: '150px'
      },
      {
        text: 'Stock Name',
        value: 'stockName',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  props: {
    stockId: {
      type: [Number , String],
      default: undefined
    }
  },
  computed: {
    cardInitialData() {
      return {
        cardDateTime: undefined,
        stockFrom: this.stockId,
        hn: undefined,
        encounterId: undefined,
      }
    }
  }
}
</script>

<style lang="css">
</style>
